import React from 'react';

import css from './Loading.module.scss';

const Loading = () => {
    return (
        <div className={css.loading_container}>
            <div className={css.loading_container__element}>
                <div className={css.loading_container__element__title} />
                <div className={css.loading_container__element__description} />
                <div className={css.loading_container__element__article} />
            </div>
            <div className={css.loading_container__element}>
                <div className={css.loading_container__element__title} />
                <div className={css.loading_container__element__description} />
                <div className={css.loading_container__element__article} />
            </div>
            <div className={css.loading_container__element}>
                <div className={css.loading_container__element__title} />
                <div className={css.loading_container__element__description} />
                <div className={css.loading_container__element__article} />
            </div>
            <div className={css.loading_container__element}>
                <div className={css.loading_container__element__title} />
                <div className={css.loading_container__element__description} />
                <div className={css.loading_container__element__article} />
            </div>
            <div className={css.loading_container__element}>
                <div className={css.loading_container__element__title} />
                <div className={css.loading_container__element__description} />
                <div className={css.loading_container__element__article} />
            </div>
        </div>
    );
};

export default Loading;
