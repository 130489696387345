import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import { useRouter } from 'next/router';
import { useToasts } from 'react-toast-notifications';

import Button from '@components/common/Button/Button';
import Input from '@components/common/Formik/FormikInputField';
import useTranslateLpc from '@components/common/hooks/useTranslateLpc';
import HelpCenterService from '@services/domain/Lpc/HelpCenterService';
import { ROUTE } from '@services/http/Route';

import css from './SearchBar.module.scss';

type SearchBarProps = {
    onSelectQuestion?: (questionID: number, thematicID: number, subThematicID: number) => void;
    searchOnPage?: (keywords: string) => void;
};

const SearchBar = ({ onSelectQuestion, searchOnPage }: SearchBarProps) => {
    const router = useRouter();
    const { addToast } = useToasts();
    const { t } = useTranslateLpc(['help-center', 'common']);
    const [searchValue, setSearchValue] = useState<string>('');
    const [disabled, setDisabled] = useState<boolean>(true);
    const [questions, setQuestions] = useState<any>([]);
    const [questionsSize, setQuestionsSize] = useState<number>(0);

    const getInitialValues = () => {
        return {
            searchField: router.query.keywords || '',
        };
    };

    const onSearchFieldChange = (event) => {
        const field = event.target.value.trim();
        if (field === '') {
            setDisabled(true);
            setQuestions([]);
            setQuestionsSize(0);
        } else if (searchValue !== field) {
            setSearchValue(field);
            setDisabled(false);
            HelpCenterService.search(field)
                .then((questions: any) => {
                    setQuestions(questions.data);
                    setQuestionsSize(questions.data.length);
                })
                .catch(() => {
                    addToast(t(`errors.DEFAULT_ERROR_MESSAGE`), {
                        appearance: 'error',
                        autoDismiss: true,
                    });
                });
        }
    };

    const handleOnSelect = (question) => {
        let param = '?question=' + question.questionID + '&thematic=' + question.thematicID;
        if (question.subThematicID) {
            param += '&subThematic=' + question.subThematicID;
        }
        if (router.asPath.startsWith(ROUTE.LPC.HELP_CENTER.DETAILS)) {
            onSelectQuestion(
                question.questionID,
                question.thematicID,
                question.subThematicID ? question.subThematicID : 0,
            );
            setSearchValue('');
            setQuestions([]);
            setQuestionsSize(0);
        } else {
            router
                .push({
                    pathname: ROUTE.LPC.HELP_CENTER.DETAILS,
                    search: new URLSearchParams(param).toString(),
                })
                .then();
        }
    };

    const onSubmit = (values) => {
        if (router.asPath.startsWith(ROUTE.LPC.HELP_CENTER.RESULT)) {
            searchOnPage(values.searchField);
            setSearchValue('');
            setQuestions([]);
            setQuestionsSize(0);
        } else {
            router.push({
                pathname: ROUTE.LPC.HELP_CENTER.RESULT,
                search: '?' + new URLSearchParams({ keywords: values.searchField }).toString(),
            });
        }
    };

    const makeBold = (question) => {
        const questionWords = question.split(' ');
        const searchValueWords = searchValue.toLowerCase().split(' ');
        let finalQuestion = '';
        for (let i = 0; i < questionWords.length; i++) {
            if (searchValueWords.indexOf(questionWords[i].toLowerCase()) > -1) {
                finalQuestion += ' <b>' + questionWords[i] + '</b>';
            } else {
                finalQuestion += ' ' + questionWords[i];
            }
        }
        return finalQuestion;
    };

    const formatResult = (question, index) => {
        return (
            <p className={css.form_container_one_result} key={index} onClick={() => handleOnSelect(question)}>
                <span dangerouslySetInnerHTML={{ __html: makeBold(question?.titleQU) }} />
            </p>
        );
    };

    return (
        <div className={css.form_container}>
            <Formik initialValues={getInitialValues()} onSubmit={(values) => onSubmit(values)}>
                {() => (
                    <Form className={css.form_container}>
                        <div className={css.form_container_input_field}>
                            <Input
                                name="searchField"
                                type="text"
                                placeholder={t('main-page.search-placeholder')}
                                onHandleChange={(event) => onSearchFieldChange(event)}
                                autoComplete={false}
                            />
                            {questionsSize > 0 && (
                                <div className={css.form_container_result}>
                                    {questions?.map((question, index) => formatResult(question, index))}
                                </div>
                            )}
                        </div>
                        <div className={css.form_container_btn}>
                            <Button width={'100%'} variant="primary" type="submit" isDisabled={disabled}>
                                {t('common.cta-search')}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default SearchBar;
