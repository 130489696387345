import React, { useEffect, useState } from 'react';

import useTranslateLpc from '@components/common/hooks/useTranslateLpc';
import Loading from '@components/lpc/Help-center/MainPage/ThematicsBlocks/Loading/Loading';
import HelpCenterService from '@services/domain/Lpc/HelpCenterService';

import OneThematic from './OneThematic/OneThematic';
import css from './ThematicsBlocks.module.scss';

const ThematicsBlocks = () => {
    const { t } = useTranslateLpc('help-center');
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [thematics, setThematics] = useState<[]>();

    useEffect(() => {
        HelpCenterService.thematics().then((thematics: any) => {
            setThematics(thematics.data);
            setIsLoaded(true);
        });
    }, []);

    return (
        <>
            {!isLoaded && <Loading />}
            {isLoaded && (
                <>
                    <div className={css.title_container}>
                        <h1>{t('main-page.our-thematics')}</h1>
                    </div>
                    <div className={css.thematics_container}>
                        {thematics?.map((thematic, index) => <OneThematic key={index} thematic={thematic} />)}
                    </div>
                </>
            )}
        </>
    );
};

export default ThematicsBlocks;
