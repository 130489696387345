import React from 'react';

import { useRouter } from 'next/router';

import useTranslateLpc from '@components/common/hooks/useTranslateLpc';
import Text from '@components/common/Text/Text';
import { ROUTE } from '@services/http/Route';

import css from './OneThematic.module.scss';

type OneThematicProps = {
    thematic: any;
};

const OneThematic = ({ thematic }: OneThematicProps) => {
    const router = useRouter();

    const { t } = useTranslateLpc('help-center');

    const goTo = () => {
        router.push({
            pathname: ROUTE.LPC.HELP_CENTER.DETAILS,
            search: '?' + new URLSearchParams({ thematic: thematic.thematicID }).toString(),
        });
    };

    const hash = crypto.getRandomValues(new Uint32Array(1)).toString();
    return (
        <div className={css.thematic_container} onClick={goTo}>
            <div className={css.thematic_container__content}>
                {thematic?.iconTH && (
                    <img src={process.env.NEXT_PUBLIC_BACKEND_URL + thematic?.iconTH + '?h=' + hash} />
                )}
                <Text customClass={css.thematic_container__content_title} color={'txt-primary'}>
                    {thematic?.titleTH}
                </Text>
                <div className={css.thematic_container__content_scrollable}>
                    <Text
                        customClass={css.thematic_container__content_description}
                        variant={'body_02'}
                        color={'txt-primary'}
                    >
                        <span dangerouslySetInnerHTML={{ __html: thematic?.descriptionTH }} />
                    </Text>
                </div>
            </div>
            <Text customClass={css.thematic_container__content_articles} variant={'body_01'} color={'brand-primary'}>
                {thematic?.articlesNumber > 1 ? (
                    <> {t('main-page.articles', { count: thematic?.articlesNumber })} </>
                ) : (
                    <> {t('main-page.article', { count: thematic?.articlesNumber })} </>
                )}
            </Text>
        </div>
    );
};
export default OneThematic;
