import React from 'react';

import dynamic from 'next/dynamic';

import useTranslateLpc from '@components/common/hooks/useTranslateLpc';
import Layout from '@components/lpc/Layout/Layout';

import css from './MainHelpCenterPage.module.scss';

const ThematicsBlocks = dynamic(() => import('@components/lpc/Help-center/MainPage/ThematicsBlocks/ThematicsBlocks'));
const SearchBar = dynamic(() => import('@components/lpc/Help-center/MainPage/SearchBar/SearchBar'));

const MainHelpCenterPage = () => {
    const { t } = useTranslateLpc('help-center');

    return (
        <Layout isHomePage>
            <div className={css.title_container}>
                <h1>{t('main-page.title')}</h1>
            </div>
            <div className={css.search_bar_container}>
                <SearchBar />
            </div>
            <ThematicsBlocks />
        </Layout>
    );
};

export default MainHelpCenterPage;
