import { NB_QUESTIONS_TO_SHOW, NB_THEMATICS_TO_SHOW } from '@components/lpc/Constants';
import ApiLpc from '@services/http/ApiLpc';
import { axiosClient, getUrl } from '@services/http/Http';

const search = (body, ctx?: string) =>
    axiosClient(ctx).get(getUrl(ApiLpc.HELP_CENTER.TITLE_SEARCH, body, NB_THEMATICS_TO_SHOW));

const thematics = (ctx?: string) => axiosClient(ctx).get(getUrl(ApiLpc.HELP_CENTER.THEMATICS, NB_THEMATICS_TO_SHOW));

const thematicsDetails = (ctx?: string) =>
    axiosClient(ctx).get(getUrl(ApiLpc.HELP_CENTER.THEMATICS_DETAILS, NB_THEMATICS_TO_SHOW));

const keywordsSearch = (body, ctx?: string) =>
    axiosClient(ctx).get(getUrl(ApiLpc.HELP_CENTER.KEYWORDS_SEARCH, body, NB_QUESTIONS_TO_SHOW, NB_THEMATICS_TO_SHOW));

const saveReaction = (questionID, action, ctx?: string) =>
    axiosClient(ctx).post(getUrl(ApiLpc.HELP_CENTER.REACTION, questionID, action));

const HelpCenterService = {
    search,
    thematicsDetails,
    thematics,
    keywordsSearch,
    saveReaction,
};

export default HelpCenterService;
